<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="userinfo">
            <img class="bg" src="@/assets/images/Rectangle1062.png" alt="" />
            <div class="detail">
                <div class="left">
                    <img class="active" :src="userInfo.avatar" alt="" />
                    <div class="info">
                        <div class="name">{{ userInfo.nickName }}</div>
                        <div class="desc">{{ userInfo.sex ? '男' : '女' }}</div>
                    </div>
                </div>
                <div class="right">
                    <div class="num">{{ total }}</div>
                    <div class="desc">帖子</div>
                </div>
            </div>
        </div>
        <div class="list1">
            <div class="item" @click="$router.push({ path: '/communityPostsDetail', query: { id: item.id } })" v-for="(item, index) in list" :key="index">
                <div class="top">
                    <div class="left">
                        <img :src="item.avatar" alt="" />
                        <span>{{ item.nickName }}</span>
                        <span>{{ item.createTime }}</span>
                    </div>
                    <div class="right">
                        <div class="items" @click.stop="collection(item)">
                            <img :src="item.collectionId ? require('../../assets/images/Frame-32.png') : require('../../assets/images/Frame-31.png')" alt="" />
                            <span>{{ item.collectionCount || 0 }}</span>
                        </div>
                        <div class="items">
                            <img src="@/assets/images/Group622.png" alt="" />
                            <span>{{ item.commentCount || 0 }}</span>
                        </div>
                        <div class="items" @click.stop="praise(item)">
                            <img :src="item.praiseId ? require('../../assets/images/Frame-30.png') : require('../../assets/images/Frame-29.png')" alt="" />
                            <span>{{ item.praiseCount || 0 }}</span>
                        </div>
                    </div>
                </div>
                <div class="content">{{ item.content || '' }}</div>
                <div class="img">
                    <img v-for="(i, idx) in item.picUrl.split(',')" :key="idx" :src="i" alt="" />
                </div>
            </div>
        </div>
        <el-pagination @size-change="getData" @current-change="getData" :current-page.sync="page" :page-sizes="[12, 20, 30, 50]" :page-size.sync="limit" layout="prev, pager, next,jumper" class="pagination" :total="total" background></el-pagination>
    </div>
</template>

<script>
export default {
    name: 'detail',
    data() {
        return {
            tabs: 0,
            limit: 12,
            page: 1,
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '用户主页',
                },
            ],
            total: 0,
            list: [],
            userInfo: {},
        };
    },
    created() {
        this.userInfo = JSON.parse(this.$route.query.item);
        this.getData();
    },
    methods: {
        getData() {
            this.$hao
                .getUserPosts({
                    pageNum: this.page,
                    pageSize: this.limit,
                    userId: this.userInfo.userId,
                    status: 1,
                })
                .then((res) => {
                    this.total = res.count;
                    this.list = res.data;
                });
        },
        collection(item) {
            if (item.collectionId === null) {
                this.$hao.addPraiseCollection({ relatedId: item.id, type: 4 }).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('收藏成功');
                        item.collectionId = res.data.id;
                        item.collectionCount++;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            } else {
                this.$hao.delPraiseCollection(item.collectionId).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('取消收藏成功');
                        item.collectionId = null;
                        item.collectionCount--;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }
        },
        // 点赞
        praise(item) {
            if (item.praiseId === null) {
                this.$hao.addPraiseCollection({ relatedId: item.id, type: 8 }).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('点赞成功');
                        item.praiseId = res.data.id;
                        item.praiseCount++;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            } else {
                this.$hao.delPraiseCollection(item.praiseId).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('取消点赞成功');
                        item.praiseId = null;
                        item.praiseCount--;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }
        },
    },
};
</script>

<style scoped lang="less">
.userinfo {
    width: 100%;
    position: relative;

    .bg {
        width: 100%;
        height: 200px;
        position: absolute;
        z-index: -1;
    }

    .detail {
        width: 100%;
        padding: 120px 0 24px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;

        .left {
            display: flex;
            align-items: center;

            .active {
                border-radius: 50%;
                width: 56px;
                height: 56px;
                border: 4px solid rgba(255, 255, 255, 0.2);
            }

            .info {
                margin-left: 10px;

                .name {
                    font-weight: 700;
                    font-size: 19px;
                    line-height: 28px;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                }

                .desc {
                    display: flex;
                    align-items: center;
                    margin-top: 4px;
                    cursor: pointer;
                    font-size: 14px;
                    line-height: 20px;
                    color: #ffffff;
                    flex: none;
                    order: 1;
                    flex-grow: 0;
                    margin-left: 4px;
                }
            }
        }

        .right {
            display: flex;
            align-items: center;
            padding: 0 41.25px;
            flex-direction: column;
            cursor: pointer;

            .num {
                font-weight: 800;
                font-size: 20px;
                line-height: 24px;
                flex: none;
                order: 0;
                flex-grow: 0;
            }

            .desc {
                font-size: 12px;
                line-height: 17px;
                flex: none;
                order: 1;
                flex-grow: 0;
                margin-top: 2px;
            }
        }
    }
}

.list1 {
    margin-top: 24px;

    .item {
        display: flex;
        flex-direction: column;
        page-break-inside: avoid;
        width: 100%;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        margin-bottom: 12px;
        padding: 24px;

        .top {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;

                img {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    margin-right: 8px;
                }

                span {
                    margin-right: 8px;
                    font-weight: 400;
                    font-size: 13px;
                    color: #333333;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                }

                span:last-child {
                    color: #999999;
                }
            }

            .right {
                display: flex;
                align-items: center;

                .items {
                    display: flex;
                    align-items: center;
                    margin-left: 24px;

                    img {
                        width: 18px;
                        height: 18px;
                        margin-right: 4px;
                    }

                    span {
                        font-size: 14px;
                        line-height: 20px;
                        color: #797a87;
                    }
                }
            }
        }

        .content {
            padding: 12px 0;
            font-size: 14px;
            line-height: 17px;
            color: #333333;
        }

        .img {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 4px;
            width: 50%;

            img {
                width: 100%;
                margin-top: 4px;
            }
        }
    }
}
</style>
